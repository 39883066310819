import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import { jsx, Grid, Flex, Box, Text, Button, Container } from 'theme-ui';
import Img from 'gatsby-image';
import shortid from 'shortid';

import SEO from '../components/SEO';
import PageBody from '../components/PageBody';
import linkResolver from '../utils/linkResolver';

const ProductBox = ({
  id,
  title,
  subtitle,
  image,
  price,
  ribbon,
  actionLabel,
  actionURL,
}) => (
  <Box
    sx={{
      position: 'relative',
      bg: 'bg.grey',
      textAlign: 'center',
      px: 3,
      py: 4,
      width: '100%',
    }}
  >
    <Img fixed={image.localFile.childImageSharp.fixed} />
    <Text
      sx={{ color: 'brand', mb: 3 }}
      dangerouslySetInnerHTML={{ __html: title.html }}
    />
    <Text sx={{ mb: 4, fontWeight: 'light' }}>{subtitle}</Text>
    <Text sx={{ mb: 3, fontWeight: 'bold', color: 'brand' }}>{price}</Text>
    {/* <Ribbon>{ribbon}</Ribbon> */}
    <Button
      id={id}
      sx={{ bg: 'accent', color: 'brand' }}
      variant="fancyPrimary"
      as={Link}
      to={linkResolver(actionURL)}
    >
      {actionLabel}
    </Button>
  </Box>
);
ProductBox.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired,
  subtitle: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  price: PropTypes.string.isRequired,
  ribbon: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  actionURL: PropTypes.object.isRequired,
};

const CollectionTemplate = ({ data, location, path }) => {
  const {
    prismicCollection: { data: page },
    subCollections,
  } = data;

  // console.log('PAGE DATA: ', page);
  // console.log('subCollections: ', subCollections);

  if (!page) return null;

  // const {
  //   meta_title: metaTitle,
  //   meta_description: metaDescription,
  //   social_card: { localFile: socialCardImage },
  // } = page;

  return (
    <Container as="section" variant="content" sx={{ my: 5, px: [3, 0, 0] }}>
      {/* <div>
        {path
          .split('/')
          .map(
            (elem, i) =>
              elem.length > 0 && <span key={`elem${i}`}>&gt;{elem}</span>
          )}
      </div> */}
      <Text
        as="h1"
        sx={{
          mb: 5,
          textAlign: 'center',
          color: 'brand',
        }}
      >
        {page.title.text}
      </Text>
      <Grid columns={[1, 2, 3]} sx={{ mb: 5 }}>
        {subCollections.edges.map(({ node }) => (
          <Link to={node.uid}>
            <Flex
              sx={{
                p: 3,
                background:
                  'linear-gradient(45deg, #45166a, #83157b 75%, #bc158a)',
                color: 'white',
                minHeight: 100,
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 5,
              }}
            >
              {node.data.title.text}
            </Flex>
          </Link>
        ))}
      </Grid>
      <SEO pathname={location.pathname} title={page.title.text} />
      {/* <PageBody document={page} /> */}
      <Grid columns={[1, 2, 3]}>
        {page.products.map(({ product }, i) => {
          const {
            title,
            subtitle,
            image,
            price,
            ribbon,
            action_label: actionLabel,
            action_url: actionURL,
          } = product.document.data;
          return (
            // <ProductListingItem key={product.id} product={product} />
            // <div>
            //   <div>{product.document.data.title.text}</div>
            //   <div>{product.document.data.subtitle}</div>
            //   <div>{product.document.data.price}</div>
            // </div>
            <ProductBox
              key={shortid.generate()}
              id={`product-box-button-${i + 1}`}
              title={title}
              subtitle={subtitle}
              image={image}
              price={price}
              ribbon={ribbon}
              actionLabel={actionLabel}
              actionURL={actionURL}
            />
          );
        })}
      </Grid>
      {page.description && (
        <Box
          width={2 / 3}
          mx="auto"
          sx={{
            mt: 5,
            p: {
              my: 4,
            },
          }}
        >
          <Text
            textAlign="center"
            dangerouslySetInnerHTML={{ __html: page.description.html }}
          />
        </Box>
      )}
    </Container>
  );
};

CollectionTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CollectionTemplate;

export const query = graphql`
  query CollectionBySlug($uid: String!, $lang: String!) {
    prismicCollection(uid: { eq: $uid }, lang: { eq: $lang }) {
      data {
        title {
          text
          html
        }
        description {
          html
        }
        products {
          product {
            slug
            document {
              ... on PrismicProductBox {
                id
                data {
                  title {
                    text
                    html
                  }
                  subtitle
                  price
                  ribbon
                  image {
                    localFile {
                      childImageSharp {
                        fixed(width: 200, height: 200) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                  action_label
                  action_url {
                    uid
                    type
                    url
                    target
                  }
                }
              }
            }
          }
        }
      }
    }
    subCollections: allPrismicCollection(
      filter: { lang: { eq: $lang }, data: { parent: { slug: { eq: $uid } } } }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`;
